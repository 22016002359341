
import BudgetsBooksService from "@/modules/budgets-books/budgets-books-service";

export default {
    create(payload) {
        return new Promise((resolve, reject) => {
            BudgetsBooksService.create(payload).then(response => {
                resolve({
                    message: "BudgetsBooks criada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao criar BudgetsBooks",
                    success: false,
                    error
                });
            })
        })
    },
    update(payload) {
        return new Promise((resolve, reject) => {
            BudgetsBooksService.update(payload.id,payload).then(response => {
                resolve({
                    message: "BudgetsBooks atualizada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao atualizar BudgetsBooks",
                    success: false,
                    error
                });
            })
        })
    },
    remove(id) {
        return new Promise((resolve, reject) => {
            BudgetsBooksService.remove(id).then(response => {
                resolve({
                    message: "BudgetsBooks removida com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao remover BudgetsBooks",
                    success: false,
                    error
                });
            })
        })
    },
    delete(id) {
        return new Promise((resolve, reject) => {
            BudgetsBooksService.delete(id).then(response => {
                resolve({
                    message: "BudgetsBooks deletada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao deletar BudgetsBooks",
                    success: false,
                    error
                });
            })
        })
    },
    get(id) {
        return new Promise((resolve, reject) => {
            BudgetsBooksService.get(id).then(response => {
                resolve({
                    message: "BudgetsBooks encontrada com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsBooks",
                    success: false,
                    error
                });
            })
        })
    },
    paginate(query) {
        return new Promise((resolve, reject) => {
            BudgetsBooksService.paginate(query).then(response => {
                resolve({
                    message: "BudgetsBooks encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsBooks",
                    success: false,
                    error
                });
            })
        })
    },
    all() {
        return new Promise((resolve, reject) => {
            BudgetsBooksService.all().then(response => {
                resolve({
                    message: "BudgetsBooks encontradas com sucesso",
                    success: true,
                    data: response.data
                });
            }).catch(error => {
                console.error(error);
                reject({
                    message: "Erro ao encontrar BudgetsBooks",
                    success: false,
                    error
                });
            })
        })
    }
}
